import React, { useEffect, useRef, useState } from "react";
import { defaultColors, styled } from "../../../stitches.config";
import { ReactComponent as BellOnIcon } from "../../assets/icons/bell-on.svg?react";
import { ReactComponent as BellOffIcon } from "../../assets/icons/bell-off.svg?react";
import { h4Strong, body, bodySmall } from "../../commonStyles/Fonts";
import { useIndexedDB } from "react-indexed-db";
import truncate from "../../../helpers/truncate";
import Media from "../../../components/common/Media";

const convertMsToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  days = days % 7;

  return { days, hours, minutes, seconds };
};

const getDiffTime = (date: string) => {
  const date1: Date = new Date(date);
  const date2: Date = new Date();
  // @ts-ignore
  const diffTime = Math.abs(date2 - date1);
  return convertMsToTime(diffTime);
};

const getStringDate = (date: string) => {
  const { days, hours, minutes } = getDiffTime(date);
  if (days > 1) {
    return `${days} dni temu`;
  } else if (days === 1) {
    return "1 dzień temu";
  } else if (hours) {
    return `${hours} godz. temu`;
  } else if (minutes) {
    return `${minutes} min. temu`;
  }
};

const SingleNotification = ({
  notification,
  isNotificationActive,
}: {
  notification: any;
  isNotificationActive: (id: number) => boolean;
}) => {
  const { add, getAll } = useIndexedDB("notifications");
  const onClick = () => add({ visitedId: notification.OID });
  return (
    <SingleNotificationWrapper href={notification.Url} onClick={onClick}>
      <SingleNotificationPicture>
        {notification.Picture && (
          <Media
            height={56}
            width={56}
            src={notification.Picture?.img}
            preserveRatio={false}
            aspectRatio={1}
          />
        )}
      </SingleNotificationPicture>
      <div>
        <p className={body()}>{truncate(notification.Message, 50)}</p>
        <span className={bodySmall()}>
          {getStringDate(notification.PublishDate)}
        </span>
      </div>
      <Dot>{!isNotificationActive(notification.OID) && <ActiveDot />}</Dot>
    </SingleNotificationWrapper>
  );
};

const Notifications = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { add, getAll } = useIndexedDB("notifications");
  const [visitedNotifications, setVisitedNotifications] = useState<number[]>(
    []
  );
  const ref = useRef(null);

  useEffect(() => {
    getAll().then((data) => {
      setVisitedNotifications(data.map(({ visitedId }) => visitedId));
    });
  }, [window.APP_SETTINGS.visitornotifications]);

  const isNotificationActive = (id: number) =>
    visitedNotifications.includes(id);

  const toggleOpen = () => setOpen((prev) => !prev);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const isNotificationAlert = window.APP_SETTINGS.visitornotifications?.filter(
    (el) => !visitedNotifications.includes(el.OID)
  ).length;

  return (
    <Wrapper
      ref={ref}
      isOpen={isOpen}
      onClick={toggleOpen}
      data-analytics-name="nav-dzwonek"
    >
      {isNotificationAlert ? (
        <BellOnIcon className="bell-animation" />
      ) : (
        <BellOffIcon />
      )}
      {isOpen && (
        <NotificationCenter>
          <NotificationCenterHeader className={h4Strong()}>
            Powiadomienia
          </NotificationCenterHeader>
          <NotificationCenterContent>
            {window.APP_SETTINGS.visitornotifications?.length ? (
              window.APP_SETTINGS.visitornotifications?.map((notification) => (
                <SingleNotification
                  notification={notification}
                  isNotificationActive={isNotificationActive}
                />
              ))
            ) : (
              <p className={body()}>Brak powiadomień</p>
            )}
          </NotificationCenterContent>
        </NotificationCenter>
      )}
    </Wrapper>
  );
};

export default Notifications;

const Wrapper = styled("button", {
  position: "relative",
  variants: {
    isOpen: {
      true: {
        svg: {
          path: {
            fill: "$primary",
          },
        },
      },
    },
  },
  svg: {
    ellipse: {
      fill: "$primary",
    },
  },
});

const NotificationCenter = styled("div", {
  backgroundColor: defaultColors.grayscale100,
  position: "absolute",
  top: "100%",
  right: "-48px",
  width: "100vw",
  padding: "24px 16px",
  zIndex: "20000",
  fontSize: "24px",
  lineHeight: "30px",
  "@bp4": {
    top: "calc(100% + 8px)",
    right: 0,
    width: "324px",
    padding: "16px",
    fontSize: "18px",
    lineHeight: "24px",

    "&:before": {
      content: "",
      backgroundColor: defaultColors.grayscale100,
      position: "absolute",
      width: "6px",
      height: "6px",
      transform: "rotate(45deg)",
      top: "-3px",
      right: "30px",
    },
  },
});

const NotificationCenterHeader = styled("h4", {
  color: defaultColors.grayscale0,
  margin: 0,
  padding: 0,
  textAlign: "left",
});

const NotificationCenterContent = styled("div", {
  p: {
    color: defaultColors.grayscale0,
    textAlign: "center",
  },
});

const SingleNotificationWrapper = styled("a", {
  display: "grid !important",
  gridTemplateColumns: "2fr 6fr 1fr",
  width: "100% !important",
  border: "none !important",
  alignItems: "center",
  gap: "16px",
  textAlign: "left",
  marginTop: "16px !important",
  marginBottom: "16px !important",
  p: {
    textAlign: "left",
    margin: 0,
    wordBreak: "break-word",
  },
  span: {
    color: defaultColors.grayscale50,
    display: "block",
  },
});

const SingleNotificationPicture = styled("div", {
  width: "56px",
  height: "56px",
  borderRadius: "50%",
  overflow: "hidden",
  img: {
    objectFit: "cover",
  },
});

const ActiveDot = styled("div", {
  backgroundColor: "$primary",
  borderRadius: "50%",
  height: "100%",
  width: "100%",
});

const Dot = styled("div", {
  width: "8px",
  height: "8px",
  margin: "0 auto",
});
